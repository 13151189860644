<template>
  <div
    :style="cssProps"
    class="content-box mx-auto"
    :class="{ 'content-box-active': isAdvancedPackageActive }"
  >
    <v-row
      justify="center"
      style="margin-bottom: 30px;"
      class="recommended-row"
    >
      <v-col
        cols="12"
        class="text-center pa-0 "
      >
        <span class="recommended-wrapper">
          <span class="recommended">{{ $t("product-packages.advanced-package.recommended") }}</span>
        </span>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        cols="12"
        class="text-center pa-0 advanced-package-title-wrapper"
      >
        <h2 class="advanced-package-title">
          {{ $t("product-packages.advanced-package.title") }}
        </h2>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      class="ma-0"
    >
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-list-item-group>
          <v-list-item
            v-for="item in packages"
            :key="item.text"
            :ripple="false"
            class="px-0 list-item-group"
          >
            <v-list-item-icon class="ma-0 pt-1">
              <v-icon
                class="check-icon mr-2"
              >
                mdi mdi-check-circle
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content class="pa-0 ma-0">
              <v-list-item-title>
                <h4 class="list-item-title">
                  {{ item.title }}
                </h4>
              </v-list-item-title>
              <h5 class="list-item-content">
                {{ item.text }}
              </h5>
              <template v-if="item.list">
                <ul v-if="item.list[0].length > 0">
                  <li
                    v-for="listItem in item.list"
                    :key="listItem"
                    class="list-item"
                  >
                    {{ listItem }}
                  </li>
                </ul>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      class="pa-0 mx-0 price-wrapper"
    >
      <v-col
        cols="12"
        class="text-center  pa-0 ma-0"
      >
        <span class="price mr-1">
          <span v-if="isMonthlyPaying">{{ formatPrice(advancedPackagePrice.MONTHLY, currency, 2) }}</span>
          <span v-if="isAnnualPaying">{{ formatPrice(Math.round(advancedPackagePrice.ANNUALLY / 12), currency, 2) }}</span>
        </span>
        <span>/ {{ $t("product-packages.month") }} + {{ $t("product-packages.advanced-package.tax-label") }}</span><br>
        <span
          v-if="isAnnualPaying"
          class="billed"
        >
          <span v-if="isMonthlyPaying"> {{ formatPrice(advancedPackagePrice.MONTHLY * 12, currency, 2) }} </span>
          <span v-if="isAnnualPaying"> {{ formatPrice(advancedPackagePrice.ANNUALLY, currency, 2) }} </span>
          / {{ $t("product-packages.annual") }} + {{ $t("product-packages.advanced-package.tax-label") }}
        </span>
      </v-col>
      <v-img
        width="80"
        :style="{'margin-top': isMonthlyPaying ? 37 + 'px' : 60 + 'px'}"
        class="image d-flex justify-center align-center"
        :src="require('@/../public/img/product-packages/ellipse.svg')"
        contain
      >
        <div class="text-center">
          <p class="seven-days col-12 ma-0 pa-0">
            {{ $t("product-packages.advanced-package.discount-info.label-one") }}
          </p>
          <p class="money-back col-12 ma-0 pa-0">
            {{ $t("product-packages.advanced-package.discount-info.label-two") }}
          </p>
          <p class="guarantee col-12 ma-0 pa-0">
            {{ $t("product-packages.advanced-package.discount-info.label-three") }}
          </p>
        </div>
      </v-img>
    </v-row>

    <v-row
      justify="center"
      class="ma-0"
    >
      <v-col
        cols="12"
        class="text-center pa-0 submit-button-wrapper"
      >
        <v-btn
          elevation="0"
          class="submit-button"
          :style="buttonCss"
          :disabled="isAdvancedPackageActive"
          @click="submit"
        >
          <span v-if="!hasActivePackage">
            {{ $t("buttons.product-packages.advanced-package.label") }}
          </span>
          <span v-else>
            {{ $t('buttons.product-packages.advanced-package.upgrade-warning') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        class="pa-0 ma-0 text-center"
        cols="12"
      >
        <span
          v-if="isMonthlyPaying"
          class="no-credit-card-label"
        >
          {{ $t("buttons.product-packages.advanced-package.info-one") }}
        </span>
        <h6
          v-if="isAnnualPaying"
          class="no-credit-card-label"
        >
          {{ $t("buttons.product-packages.advanced-package.info-two") }}
        </h6>
      </v-col>
    </v-row>

    <v-row
      v-if="hasActivePackage"
      justify="center"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <UpdatePackageInfo
          v-if="!isAdvancedPackageActive"
          type="info"
          :package-type="productType"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PayPeriod } from '../enums/PayPeriod'
import brandingMixin from '@/mixins/branding'
import { AdvancedPayPeriodId } from '../enums/ReadablePayPeriodId'
import { AdvancedPackagePrice } from '../enums/PackagePrice'
import featureMixin from '@/mixins/feature'
import { getProductPackageQuery } from '../lib/productQuery'
import UpdatePackageInfo from '../info/UpdatePackageInfo.vue'
import currencyMixin from '@/mixins/currency'
import { CurrencySymbol } from '@/modules/productPackages/enums/CurrencySymbol'
export default {
  components: { UpdatePackageInfo },
  mixins: [brandingMixin, featureMixin, currencyMixin],
  props: {
    isBasicPackageActive: {
      type: Boolean,
      default: false
    },
    isAdvancedPackageActive: {
      type: Boolean,
      default: false
    },
    productType: {
      type: String,
      default: ''
    },
    payingPeriod: {
      type: String,
      default: ''
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    fallbackCountryCode: {
      type: String,
      default: ''
    },
    hasActivePackage: {
      type: Boolean,
      default: false
    },
    activePackage: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      packages: [
        {
          title: this.translate('benefit-one.title'),
          text: this.translate('benefit-one.text')
        },
        {
          title: this.translate('benefit-two.title'),
          text: this.translate('benefit-two.text')
        },
        {
          title: this.translate('benefit-three.title'),
          text: this.translate('benefit-three.text'),
          list: [
            this.translate('benefit-three.list.0'),
            this.translate('benefit-three.list.1'),
            this.translate('benefit-three.list.2')
          ]
        },
        {
          title: this.translate('benefit-four.title'),
          text: this.translate('benefit-four.text')
        },
        {
          title: this.translate('benefit-five.title'),
          text: this.translate('benefit-five.text')
        }
      ],
      isUpgrade: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--mid-yellow': this.$vuetify.theme.themes.light.markero.midYellow,
        '--light-orange': this.$vuetify.theme.themes.light.markero.lightOrange
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    currency () {
      const currencyMap = {
        [CurrencySymbol.SWISSFRANC]: 'CHF',
        [CurrencySymbol.EURO]: 'EUR'
      }

      return currencyMap[this.currencySymbol] || 'USD'
    },
    infoColor () {
      return {
        color: this.$vuetify.theme.themes.light.markero.blue
      }
    },
    buttonCss () {
      return {
        'background-color': this.$vuetify.theme.themes.light.markero.blue
      }
    },
    advancedPackagePrice () {
      return AdvancedPackagePrice
    },
    isMonthlyPaying () {
      return this.payingPeriod === PayPeriod.MONTHLY
    },
    isAnnualPaying () {
      return this.payingPeriod === PayPeriod.ANNUALLY
    }
  },
  methods: {
    translate (key) {
      return this.$t(`product-packages.advanced-package.package-benefits.${key}`)
    },
    setToSessionStorage () {
      const { query } = this.$route
      sessionStorage.setItem('productPackageQuery', JSON.stringify({
        readableIds: this.isAnnualPaying ? [AdvancedPayPeriodId.ANNUALLY] : [AdvancedPayPeriodId.MONTHLY],
        isYearly: this.isAnnualPaying,
        productType: this.productType,
        countryCode: query?.countryCode ?? this.fallbackCountryCode,
        companyId: query?.companyId,
        currencySymbol: this.currencySymbol,
        monthlyPrice: this.isMonthlyPaying ? AdvancedPackagePrice.MONTHLY : AdvancedPackagePrice.ANNUALLY / 12,
        yearlyPrice: this.isMonthlyPaying ? AdvancedPackagePrice.MONTHLY * 12 : AdvancedPackagePrice.ANNUALLY
      }))
    },
    submit () {
      this.setToSessionStorage()
      if (this.hasActivePackage) {
        this.$tracking.event('Plans and Pricing', this.$tracking.trackingEvents.CLICKED, 'ADVANCED')
        this.$tracking.event('Plan', this.$tracking.trackingEvents.CLICKED, 'Upgrade')
        this.navigateToPaymentPage()
      } else {
        this.$tracking.event('Plan', this.$tracking.trackingEvents.CLICKED, 'ADVANCED')
        this.navigateToRegisterPage()
      }
    },

    navigateToRegisterPage () {
      const query = getProductPackageQuery()
      if (window.self !== window.top) {
        // Open in new tab if embedded on the website
        window.open(this.$router.resolve({ name: 'register', query }).href, '_blank')
      } else {
        this.$router.push({ name: 'register', query, replace: true })
      }
    },

    navigateToPaymentPage () {
      this.$router.push({ name: 'change-package-payment', query: getProductPackageQuery(), replace: true })
    }
  }
}
</script>

<style scoped>
/* General Text Styles */
.advanced-package-title,
.list-item-title,
.list-item-content,
.price-wrapper span,
.submit-button,
.no-credit-card-label,
.image span,
.recommended,
.list-item,
p {
  color: white;
  font-family: 'Poppins', sans-serif;
}

/* Recommended Styles */
.recommended-row {
  margin-top: -12%;
}

.recommended-wrapper {
  width: 176px;
  height: 37px;
  border-radius: 35px;
  background: var(--mid-yellow);
  padding: 8px 24px;
  flex-shrink: 0;
  margin-bottom: 11px;
}

.recommended {
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

/* Advanced Package Title Styles */
.advanced-package-title-wrapper {
  margin-bottom: 15px;
}

.advanced-package-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 15px;
}

/* List Item Styles */
.list-item-title {
  max-width: 310px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.list-item-content,
.no-credit-card-label,
.list-item {
  font-size: 14px;
  font-weight: 400;
}

/* Price Styles */
.price-wrapper {
  margin: 15px 0;
}

.price {
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
}

/* Submit Button Styles */
.submit-button {
  width: 100%;
  height: 52px !important;
  font-size: 16px;
  font-weight: 700;
  color: white;
  padding: 14px 34px;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
}

.submit-button-wrapper {
  margin-bottom: 10px;
}

/* Content Box Styles */
.content-box {
  width: 395px;
  height: fit-content;
  color: white;
  margin-top: -30px;
  padding: 30px;
  border-radius: 20px;
  background: var(--light-orange);
  box-shadow: 10px 10px 40px 0 rgba(47, 22, 232, 0.15);
}

.content-box-active {
  opacity: 0.5;
}

.list-item-group {
  padding-bottom: 20px;
}

/* Image Styles */
.image {
  width: 100px;
  height: 100px;
  position: absolute;
  margin-left: 300px;
  z-index: 20;
  text-shadow: 2px 2px 20px rgba(76, 76, 76, 0.20);
}

/* Guarantee and Billing Styles */
.seven-days,
.money-back,
.guarantee {
  color: white;
}

.seven-days {
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
}

.money-back {
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
}

.guarantee {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
}

.billed {
  font-size: small;
}

/* Vuetify Specific Overrides */
.v-list-item--link:before {
  background-color: transparent;
}

.v-list-item--link {
  cursor: unset;
}

.check-icon {
  color: white;
  width: 11px;
  height: 9px;
}

@media only screen and (max-width: 960px) {
  .submit-button {
    font-size: 12px;
  }
  .image {
    width: 70px;
    margin-left: 70%;
  }
  .price {
    font-size: 28px;
  }
  .content-box {
    width: 100%;
    padding: 20px;
  }
  .price-wrapper {
    margin: 10px 0;
  }

  .recommended-row {
    margin-top: -4%;
  }
}

@media (min-width: 450px) and (max-width: 650px) {
  .recommended-row {
    margin-top: -5%;
  }
  .image {
    margin-left: 80%;
  }

}

@media (min-width: 650px) and (max-width: 960px) {
  .image {
    margin-left: 85%;
  }
}

@media only screen and (max-width: 450px) {
  .recommended-row {
    margin-top: -9%;
  }
}

</style>
