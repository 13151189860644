<template>
  <div
    :style="cssProps"
    class="col additional-info pa-sm-0"
  >
    <h2 class="row justify-center additional-info-title text-center mx-auto col-sm-10 col-lg-6 py-sm-0 px-sm-0">
      {{ $t('product-finder.additional-info.title') }}
    </h2>
    <div class="row industry-box pt-10 ma-0">
      <div class="col-sm-10 col-lg-6 mx-auto pa-0">
        <h4 class="text-start industry-box-title pb-3 ma-0">
          {{ $t('product-finder.additional-info.industry-box-title') }}
        </h4>
        <IndustryField
          :value="industry"
          @input="$emit('industry', $event)"
        />
      </div>
    </div>

    <div
      class="row py-10 ma-0"
    >
      <div class="col-sm-10 col-lg-6 mx-auto ma-0 pa-0">
        <h4 class="ma-0 pa-0 mb-3 text-start description-box-title">
          {{ $t('product-finder.additional-info.description-box-title') }}
        </h4>
        <v-textarea
          ref="businessDescription"
          :value="businessDescription"
          :placeholder="$t('product-finder.enter-description-placeholder')"
          rows="5"
          class="my-2"
          outlined
          counter="5000"
          :rules="[rules.lengthWithEmptySpace({max: 5000})]"
          @input="$emit('businessDescription', $event)"
        />
      </div>
    </div>
    <div class="row ma-0">
      <div class="col-sm-10 col-lg-6 pa-0 mx-auto d-flex justify-space-between">
        <v-btn
          class="back col-6"
          elevation="0"
          x-large
          @click="$emit('back')"
        >
          {{ $t('buttons.product-finder.back') }}
        </v-btn>
        <v-btn
          class="proceed col-6 ml-2"
          elevation="0"
          :style="buttonCss"
          x-large
          @click="$emit('next')"
        >
          {{ $t('buttons.product-finder.finish') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { lengthWithEmptySpace } from '@/lib/validation'
import IndustryField from './IndustryField.vue'

export default {
  components: {
    IndustryField
  },
  props: {
    industry: {
      type: Object,
      default: () => ({})
    },
    businessDescription: {
      type: String,
      default: ''
    }
  },
  computed: {
    cssProps () {
      return {
        '--blue': this.$vuetify.theme.themes.light.markero.blue,
        '--dark-blue': this.$vuetify.theme.themes.light.markero.darkBlue
      }
    },
    buttonCss () {
      return {
        'background-color': this.$vuetify.theme.themes.light.markero.blue,
        color: 'white'
      }
    }
  },
  created () {
    this.rules = { lengthWithEmptySpace }
  }
}

</script>

<style scoped>
/* General Font Styles */
.additional-info, .additional-info-title, .description-box-title, .industry-box-title, .proceed, .back {
  font-family: 'Poppins', sans-serif;
}

/* Title Styles */
.additional-info-title, .description-box-title {
  font-style: normal;
}

.additional-info-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.description-box-title, .industry-box-title {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

/* Pointer Class */
.pointer {
  cursor: pointer;
}

/* Back Button Styles */
.back {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border: 1px solid var(--blue) !important;
  padding: 13px 34px !important;
  background-color: white !important;
  border-radius: 10px;
  color: var(--blue);
  height: fit-content !important;
  text-transform: none;
}

/* Proceed Button Styles */
.proceed {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
}

/* Vuetify Specific Overrides */
::v-deep fieldset {
  background-color: white;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin: 12px 0;
}

::v-deep .v-text-field--outlined .v-label {
  top: 15px;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px;
}

::v-deep .v-text-field input {
  padding: 10px 0 8px;
  font-family: 'Poppins', sans-serif;
}

::v-deep .theme--light.v-btn:hover::before {
  background-color: var(--dark-blue);
}
</style>
