<template>
  <div
    class="col text-center"
    style="margin-top: 30%"
  >
    <div class="row justify-center">
      <v-progress-circular
        :rotate="360"
        indeterminate
        :size="200"
        :width="20"
        color="primary"
      />
    </div>
    <h1 class="row justify-center mt-5">
      {{ $t('product-finder.generate.title') }}<br>
      {{ $t('product-finder.generate.subtitle') }}
    </h1>
  </div>
</template>
<script>

export default {
  async mounted () {
    await new Promise(resolve => setTimeout(resolve, 4000))
    this.$router.push({ name: 'dashboard' })
  }
}

</script>
