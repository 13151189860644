<template>
  <div v-if="anamnesis">
    <BasicInfo
      v-if="activeStep === 'basic-info'"
      :country="anamnesis.country"
      :website-url="anamnesis.websiteUrl"
      :new-website="anamnesis.newWebsite"
      @websiteUrl="anamnesis.websiteUrl = $event"
      @newWebsite="anamnesis.newWebsite = $event"
      @country="anamnesis.country = $event"
      @next="updateActiveStep('additional-info')"
    />
    <AdditionalInfo
      v-else-if="activeStep === 'additional-info'"
      :industry="anamnesis.industry"
      :business-description="anamnesis.businessDescription"
      @industry="anamnesis.industry = $event"
      @businessDescription="anamnesis.businessDescription = $event"
      @back="updateActiveStep('basic-info')"
      @next="saveAnamnesis"
    />
    <Generate
      v-else-if="activeStep === 'generate'"
    />
  </div>
</template>

<script>
import BasicInfo from '@/components/product-finder/BasicInfo.vue'
import AdditionalInfo from '@/components/product-finder/AdditionalInfo.vue'
import Generate from '@/components/product-finder/Generate.vue'
import brandingMixin from '@/mixins/branding'

import CREATE_COMPANY_ANAMNESIS from '@/modules/auth/Register/queries/createCompanyAnamnesis.gql'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import countries from '@/mixins/countries'

export default {
  components: { Generate, AdditionalInfo, BasicInfo },
  mixins: [brandingMixin, countries],
  props: {
    activeStep: {
      type: String,
      default: 'basic-info'
    }
  },
  data () {
    return {
      anamnesis: null
    }
  },
  watch: {
    activeStep (val) {
      this.$emit('updateActiveStep', val)
    },
    anamnesis: {
      handler (val) {
        sessionStorage.setItem('anamnesis', JSON.stringify(val))
      },
      deep: true
    }
  },
  mounted () {
    this.anamnesis = {
      country: this.availableCountries[0],
      websiteUrl: '',
      newWebsite: false,
      industry: {},
      businessDescription: '',
      ...JSON.parse(sessionStorage.getItem('anamnesis'))
    }
  },
  methods: {
    updateActiveStep (step) {
      this.$emit('updateActiveStep', step)
    },
    async saveAnamnesis () {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_COMPANY_ANAMNESIS,
          variables: {
            input: {
              businessDescription: this.anamnesis.businessDescription,
              country: this.anamnesis.country.countryCode,
              industry: this.anamnesis.industry.value,
              websiteUrl: this.anamnesis.websiteUrl.length ? formatHttpsUrl(this.anamnesis.websiteUrl) : ''
            }
          }
        })
      } catch (error) {
        showSnackbarMessage('error', this.$t('product-finder.generate.error'))
        throw error
      }
      this.updateActiveStep('generate')
      sessionStorage.removeItem('anamnesis')
    }
  }
}
</script>
