import { render, staticRenderFns } from "./BasicInfo.vue?vue&type=template&id=1bdb3c63&scoped=true&"
import script from "./BasicInfo.vue?vue&type=script&lang=js&"
export * from "./BasicInfo.vue?vue&type=script&lang=js&"
import style0 from "./BasicInfo.vue?vue&type=style&index=0&id=1bdb3c63&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bdb3c63",
  null
  
)

export default component.exports