<template>
  <div
    :style="cssProps"
    class="col basic-info pa-sm-0 text-center"
  >
    <h2 class="row justify-center basic-info-title mx-auto col-sm-10 col-lg-6 px-sm-0">
      {{ $t('product-finder.basic-info.title') }}
    </h2>

    <div class="row ma-0 country-box">
      <div class="col-sm-10 col-lg-6 mx-auto pa-0">
        <h4 class="text-start country-box-title pb-3 pt-8">
          {{ $t('product-finder.basic-info.country-box-title') }}
        </h4>
        <v-autocomplete
          :value="country"
          :items="availableCountries"
          clearable
          item-text="text"
          item-value="value"
          outlined
          hide-details
          :placeholder="$t('product-finder.basic-info.country-placeholder')"
          class="pointer"
          return-object
          @input="$emit('country', $event)"
          @click:clear="$emit('country', null)"
        >
          <template v-slot:selection="data">
            <span>{{ data.item.text }}</span>
          </template>
          <template v-slot:item="data">
            <span class="ml-2">{{ data.item.text }}</span>
          </template>
        </v-autocomplete>
      </div>
    </div>

    <div class="row pt-10 ma-0 website-box">
      <div class="col-sm-10 col-lg-6 mx-auto ma-0 pa-0 ">
        <p class="ma-0 pa-0 mb-3 text-start website-box-title">
          {{ $t('product-finder.basic-info.website-box-title') }}
        </p>
        <v-radio-group
          :value="newWebsite"
          class="mt-0 pt-0"
          row
          @change="$emit('newWebsite', $event)"
        >
          <v-radio
            class="col-12 pa-0 mb-3"
            :value="true"
          >
            <template v-slot:label>
              <p class="my-0 py-0 radio-paragraph">
                {{ $t('product-finder.basic-info.radio-two-title') }}
              </p>
            </template>
          </v-radio>
          <v-radio
            class="col-12 pa-0 mb-3"
            :value="false"
          >
            <template v-slot:label>
              <p class="my-0 py-0 radio-paragraph">
                {{ $t('product-finder.basic-info.radio-one-title') }}
              </p>
            </template>
          </v-radio>
          <v-text-field
            v-if="!newWebsite"
            ref="website"
            :value="websiteUrl"
            class="col-12 ma-0 pa-0 website-input"
            outlined
            type="url"
            :placeholder="$t('product-finder.enter-website-placeholder')"
            :rules="[rules.url]"
            validate-on-blur
            @input="$emit('websiteUrl', $event)"
          />
        </v-radio-group>
      </div>
    </div>

    <div class="row justify-center ma-0">
      <v-btn
        class="proceed col-sm-10 col-lg-6"
        elevation="0"
        :style="buttonCss"
        x-large
        @click="$emit('next')"
      >
        {{ $t('buttons.product-finder.proceed') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import { url } from '@/lib/validation'

export default {
  mixins: [countries],
  props: {
    websiteUrl: {
      type: String,
      default: ''
    },
    newWebsite: {
      type: Boolean,
      default: false
    },
    country: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    cssProps () {
      return {
        '--dark-blue': this.$vuetify.theme.themes.light.markero.darkBlue
      }
    },
    buttonCss () {
      return {
        'background-color': this.$vuetify.theme.themes.light.markero.blue,
        color: 'white'
      }
    }
  },
  created () {
    this.rules = { url }
  }
}

</script>
<style scoped>
/* General Font Styles */
.basic-info, .proceed, .basic-info-title, .country-box-title, .website-box-title, .radio-paragraph {
  font-family: 'Poppins', sans-serif;
}

/* Title Styles */
.basic-info-title, .country-box-title, .website-box-title {
  font-style: normal;
}

.basic-info-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.country-box-title, .website-box-title {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

/* Paragraph Styles */
.radio-paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

/* Pointer Class */
.pointer {
  cursor: pointer;
}

/* Proceed Button Styles */
.proceed {
  font-size: 16px;
  font-weight: 700;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
  line-height: normal;
}

/* Box Styles */
.website-box {
  height: 235px;
}

/* Vuetify Specific Overrides */
::v-deep fieldset {
  background-color: white;
}

::v-deep .v-label--active {
  color: black !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin: 12px 0;
}

::v-deep .v-text-field--outlined .v-label {
  top: 15px;
}

::v-deep .website-input .v-input__control > .v-input__slot {
  min-height: 48px !important;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px;
}

::v-deep .v-text-field input {
  padding: 10px 0 8px;
  font-family: 'Poppins', sans-serif;
}

::v-deep .theme--light.v-btn:hover::before {
  background-color: var(--dark-blue);
}

</style>
