<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="industry"
    :items="industries"
    :search-input.sync="search"
    clearable
    v-bind="$attrs"
    item-text="text"
    item-value="value"
    outlined
    hide-details
    :placeholder="$t('product-finder.industry-placeholder')"
    class="ma-0 pointer"
    return-object
    @blur="(e) => $emit('blur', e)"
    @click:clear="removeIndustry"
    @keydown.enter="addIndustry"
  >
    <template v-slot:selection="data">
      <span>{{ data.item.text }}</span>
    </template>
    <template v-slot:item="data">
      <span class="ml-2">{{ data.item.text }}</span>
    </template>
    <template v-slot:no-data>
      <v-btn
        elevation="0"
        text
        outlined
        color="primary"
        class="ml-2"
        @click="addIndustry"
      >
        {{ $t('buttons.product-finder.add') }}: {{ search }}
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<script>
import { Industries } from '@/components/product-finder/productFinder'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      industry: this.value,
      search: '',
      industries: []
    }
  },
  watch: {
    industry (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.industry = newValue
    }
  },
  mounted () {
    this.industries = this.getIndustries()
    if (this.industry && !this.industries.some(({ value }) => value === this.industry.value)) {
      this.industries.unshift(this.industry)
    }
  },
  methods: {
    addIndustry () {
      this.industries.unshift({ value: this.search, text: this.search, key: this.search })
      this.industry = { value: this.search, text: this.search, key: this.search }
      this.search = ''
      this.$refs.autocomplete.blur()
    },
    removeIndustry () {
      this.industry = {}
    },
    getIndustries () {
      return Industries.map(industry => {
        if (industry.divider) return industry
        if (industry.header) { return { header: this.$t(industry.header) } }
        if (industry.value) { return { value: industry.value, text: this.$t(industry.text), key: industry.text } }
      })
    }
  }
}

</script>

<style scoped>
/* Pointer Class */
.pointer {
  cursor: pointer;
}
</style>
